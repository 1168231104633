<ng-template #confirmModal>
  <form (ngSubmit)="onSubmit(form)" [formGroup]="form" class="fnc-modal-confirm-window">
    <mm-modal compact>
      <mm-modal-header>
        <mm-bar>
          <mm-bar-item>
            {{ title | translate }}
          </mm-bar-item>
          <mm-bar-item>
            <mm-link-icon (click)="modalRef.hide()" color="blue" kind="close"></mm-link-icon>
          </mm-bar-item>
        </mm-bar>
      </mm-modal-header>
      <mm-modal-content>
        <div *ngIf="messages.length && messagesPosition === messagesPositionsEnum.START">
          <mm-alert *ngFor="let message of messages" [alertTitle]="message" alertLinkText=" " size="small" type="error"></mm-alert>
        </div>
        <ng-content></ng-content>
        <div *ngIf="messages.length && messagesPosition === messagesPositionsEnum.END">
          <mm-alert *ngFor="let message of messages" [alertTitle]="message" alertLinkText=" " size="small" type="error"></mm-alert>
        </div>
        <mm-form-field
          *ngIf="!withoutReason"
          [control]="form.get('reason')"
          [errorData]="{ name: 'COMMON.CONFIRM_WINDOW.REASON' | translate, limit: minLength }"
        >
          <mm-label class="mm-label" for="reason"> {{ label | translate }} </mm-label>
          <textarea
            [maxlength]="maxLength"
            classList="fnc-modal-confirm-window__textarea"
            formControlName="reason"
            id="reason"
            mmTextArea
            placeholder="{{ placeholder | translate }}"
          >
          </textarea>
        </mm-form-field>
      </mm-modal-content>
      <mm-modal-footer>
        <button [kind]="confirmButtonKind" mmButton size="small" [disabled]="isSubmitDisabled" type="submit">
          {{ confirmButtonText | translate }}
        </button>
        &nbsp;
        <button (click)="modalRef.hide()" kind="secondary" mmButton size="small" type="button">
          {{ "COMMON.CONFIRM_WINDOW.CANCEL" | translate }}
        </button>
      </mm-modal-footer>
    </mm-modal>
  </form>
</ng-template>
