import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode } from '@datorama/akita';
import { AppModule } from './app/app.module';
import { AppInjector } from '@fnc-app/modules/core/services/app-injector/app-injector.service';
import { environment } from '@fnc-env/environment';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(moduleRef => {
    const injector = moduleRef.injector;
    AppInjector.setInjector(injector);
  })
  .catch(err => console.error(err));
