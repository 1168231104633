import { Route } from '@angular/router';
import { DictionaryResolverService, PermissionsGuard } from '@mm-ui/core';
import { OfferPageComponent } from '@fnc-app/modules/core/components/offer-page/offer-page.component';
import { TranslationOfferManagementModules, TranslationShippingManagementModules } from '@fnc-app/routes/translation-modules';
import { InitGuard } from '@fnc-core/guards/init.guard';
import { Dictionary } from '@fnc-shared/constants/dictionary.constant';
import { Path } from '@fnc-shared/constants/path.constant';

export const ShippingManagementRoute: Route = {
  path: Path.shippingManagemnt,
  canActivate: [InitGuard],
  component: OfferPageComponent,
  data: {
    title: 'NAVIGATION.MAIN.SHIIPING_MANAGEMENT',
    breadcrumb: 'NAVIGATION.SHIPPING_MANAGEMENT.BREADCRUMB',
    i18n: TranslationShippingManagementModules
  },
  children: [
    {
      path: '',
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      loadChildren: () => import('@fnc-modules/shipping-management/shipping-management.module').then(m => m.ShippingManagementModule),
      data: {
        title: 'NAVIGATION.SHIPPING_MANAGEMENT.BREADCRUMB',
        breadcrumb: 'NAVIGATION.SHIPPING_MANAGEMENT.SHIPPING_MANAGEMENT',
        permissions: {
          rule: ['ROLE_CAN_VIEW_INVUNITS'],
          navigateTo: ['/', Path.forbidden],
          i18n: TranslationOfferManagementModules
        },
        dictionaries: [Dictionary.SALES_CHANNELS]
      }
    }
  ]
};
