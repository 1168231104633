import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { SideMenuProtected } from '@fnc-shared/interfaces/side-menu.interface';
import { Path } from '@fnc-shared/constants/path.constant';

export const OfferSideMenuItems: SideMenuProtected[] = [
  {
    title: _('NAVIGATION.MAIN.OFFER_MANAGEMENT'),
    items: [
      {
        items: [
          {
            routerLink: ['/', Path.offerManagement, Path.saleUnits],
            title: _('NAVIGATION.OFFER_MANAGEMENT.SALE_UNITS'),
            rights: ['ROLE_CAN_VIEW_INVUNITS']
          },
          {
            routerLink: ['/', Path.offerManagement, Path.mcomOfferAssignmentPage],
            title: _('NAVIGATION.OFFER_MANAGEMENT.MCOM_OFFER_ASSIGNMENT'),
            rights: ['ROLE_CAN_VIEW_MCOM']
          }
        ]
      }
    ]
  },
  {
    title: _('NAVIGATION.MAIN.SHIPPING_MANAGEMENT'),
    items: [
      {
        items: [
          {
            routerLink: ['/', Path.shippingManagemnt, Path.defaultTemplates],
            title: _('NAVIGATION.SHIPPING_MANAGEMENT.DEFAULT_TEMPLATES'),
            rights: ['ROLE_CAN_VIEW_MCOM', 'FF_AP_SU_TESTING_PHASE']
          }
        ]
      }
    ]
  }
];
